const ContactSection = () => {
    return (
        <section id="contact" className="py-20 bg-gray-200 p-4 dark:bg-gray-900 dark:text-white border-t dark:border-b-amber-50">
            <div className="container mx-auto text-center">
                <h2 className="text-4xl font-bold mb-12">İletişim</h2>

                {/* Contact Cards */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12 text-white">
                    <div className="bg-gray-800 p-6 rounded-lg shadow-lg dark:border dark:border-amber-50">
                        <h3 className="text-2xl font-bold">Mehmet Balta</h3>
                        <p>Email: mehmetbalta73@gmail.com</p>
                        <p>Telefon: <a href="https://wa.me/905362668583" target={'_blank'} className="text-blue-400">536-266-8583</a></p>
                        <p>İvedik OSB, 1534. Sk. No:18, 06378 Yenimahalle/Ankara</p>
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg shadow-lg dark:border dark:border-amber-50">
                        <h3 className="text-2xl font-bold">Sadettin Kutlu</h3>
                        <p>Email: sadettin.kutlu.06@hotmail.com</p>
                        <p>Telefon: <a href="https://wa.me/905557654321" target={'_blank'} className="text-blue-400">532-364-5952</a></p>
                        <p>İvedik OSB, 1534. Sk. No:18, 06378 Yenimahalle/Ankara</p>
                    </div>
                </div>

                <p className={'text-3xl font-bold my-4'}>Bize Ulaşın - 0312 395 65 23</p>
                {/* Google Map */}
                <div>
                    <iframe
                        title="Oğuzhan Havalandırma Location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16746.030228618478!2d32.75069501078433!3d39.98955740879286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34a2dd04b7b13%3A0xd4051468f785b1aa!2sO%C4%9Fuzhan%20Havaland%C4%B1rma%20Sistemleri!5e0!3m2!1str!2str!4v1722024923704!5m2!1str!2str"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        className="rounded-lg shadow-lg"
                    ></iframe>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
